import React from 'react';
import './App.css';
import { Route } from 'react-router-dom';
import Nav from './Nav';
import Education from './Education';
import About from './About'
import Projects from './Projects';
import styled from 'styled-components';

const Header = styled.header`
  background-color: #0B0C10;
`

function App() {
  return (
    <div className="App">
      <Header>
        <Nav />
      </Header>
      
      <Route exact path='/'>
        <About />
      </Route>

      <Route path='/education'>
        <Education />
      </Route>

      <Route path='/projects'>
        <Projects />
      </Route> 
    </div>
  );
}

export default App;
