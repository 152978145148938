import React from 'react'
import styled from 'styled-components'

const Page = styled.div`
    color: #66FCF1;
`

const Title = styled.h1`
    text-align: left;
    margin-left: 10%;
    margin-right: 10%;
    border-bottom: 1px solid #C5C6C7;
`

const Card = styled.div`
    display: flex;
    border: 1px solid #C5C6C7;
    border-radius: 10px;
    box-shadow: 5px 5px 6px -2px rgb(128, 127, 127);
    width: 80%;
    margin: 5% auto;
    background-color: #0B0C10;
    justify-content: space-between;
    color: #C5C6C7;
    align-items: center;
`



const ImageAM = styled.img`
    width: 60%;
    border-radius: 10px;
`

const Explanation = styled.div`
    margin-right: 3%;
    width: 60%;
`

const ProjectTitleandImg = styled.div`
    margin-bottom: 2%;
    width: 40%;
`

const ProjectLink = styled.a`
    color: #C5C6C7;
`

export default function Projects() {

    return (
    <Page>
        <Title>Projects</Title>

        <Card>
            <ProjectTitleandImg>
            <h3><ProjectLink href='https://anywhere-fitness-web-app-amber.vercel.app/'>Anywhere Fitness</ProjectLink></h3>
            <ImageAM src='./AnywhereFitness.PNG'/>
            </ProjectTitleandImg>
            <Explanation>
                <ul>Description:</ul>
                <li>Built and deployed a Web API following the REST architectural pattern using the Express framework</li>
                <li>Included middleware to validate tokens and check validity of user requests</li>
                <li>Unit tests written to ensure endpoints were functioning correctly</li>
            </Explanation>
        </Card>

        <Card>
            <ProjectTitleandImg>
            <h3><ProjectLink href='https://marketing-page-theta.vercel.app/'>VR Funding Platform</ProjectLink></h3>
            <ImageAM src='./VRFunding.PNG'/> 
            </ProjectTitleandImg>
            <Explanation>
                <ul>Description:</ul>
                <li>Utilized Redux as the state management system to keep track of projects and the current user
                </li>
                <li>Implemented CRUD operations with axios to manipulate data in the back-end </li>
                <li>Incorporated token authentication and private routing/redirection</li>
                <li>Used styled-components to design the front-end</li>
                <li>Collaborated on a remote cross-functional team of 5 to deliver the project in 1 week</li>
            </Explanation>
        </Card>

        <Card>
            <ProjectTitleandImg>
            <h3><ProjectLink href='https://landing-marketing.vercel.app/'>African Marketplace</ProjectLink></h3>
            <ImageAM src='./AfricanMarketplace1.PNG'/>
            </ProjectTitleandImg>
            <Explanation>
                <ul>Description:</ul>
                <li>Utilized form management to create on the sign-up and login components</li>
                <li>Fetched and displayed data from the back-end via axios</li>
                <li>Properly installed route management to show top level pages as well as nested views where necessary</li>
                <li>Collaborated on a remote cross-functional team of 4 to deliver the project in 1 week</li>
            </Explanation>
        </Card>

        <Card>
            <ProjectTitleandImg>
            <h3><ProjectLink href='https://web-sprint-challenge-single-page-applications-kappa.vercel.app/pizza'>Lambda Eats</ProjectLink></h3>
            <ImageAM src='./LambdaEats.PNG'/>
            </ProjectTitleandImg>
            <Explanation>
                <ul>Description:</ul>
                <li>Used React to create a form in which the user can input a pizza order</li>
                <li>User data would then be displayed and posted to an API via axios</li>
                <li>End to end testing (through cypress) was also used in order to ensure the user's order was submitted successfully</li>
            </Explanation>
        </Card>

        <Card>
            <ProjectTitleandImg>
            <h3><ProjectLink href='daniel-vargas-ui-and-git.netlify.app'>User Interface and Git</ProjectLink></h3>
            <ImageAM src='./UIandGit.PNG'/>
            </ProjectTitleandImg>
            <Explanation>
                <ul>Description:</ul>
                <li>Created and designed a multipage website</li>
                <li>Utilized semantic HTML, CSS fundamentals, CSS Flexbox, and Git</li>
            </Explanation>
        </Card>

        <Card>
            <ProjectTitleandImg>
            <h3><ProjectLink href='https://react-todo-app-ten.vercel.app/'>To-Do List App</ProjectLink></h3>
            <ImageAM src='./ToDoApp.PNG'/>
            </ProjectTitleandImg>
            <Explanation>
                <ul>Description:</ul>
                <li>Used React to create an interactive To-Do list app</li>
                <li>App was built out using stateful class components</li>
            </Explanation>
        </Card>




        {/*Check out other projects on GitHub*/}
        <Card>
            <ProjectTitleandImg>
            <h3><ProjectLink href='https://github.com/davarg5'>Check out my GitHub for more!</ProjectLink></h3>
            <ImageAM src='./GithubLogo.PNG'/>
            </ProjectTitleandImg>
        </Card>
    </Page>
    )
    

}