import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Page = styled.div`
    color: #66FCF1;
`

const Title = styled.h1`
    text-align: left;
    margin-left: 10%;
    margin-right: 10%;
    border-bottom: 1px solid #C5C6C7;
`

const AboutContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0% auto;
    width: 80%;
    align-items: center;
`

const AboutPageImg = styled.img`
    width: 95%;
    border-radius: 10px;
    box-shadow: 5px 5px 6px -2px rgb(128, 127, 127);
    margin-right: 3%;
`

const AboutPageImgText = styled.div`
    font-size: 1.25rem;
    color: #C5C6C7;
    
`

const SkillsTitle = styled.h1`
    text-align: left;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5%;
    margin-bottom: 5%;
`

const ContactTitle = styled.h1`
    text-align: left;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5%;
    margin-bottom: 3%;
`

const CardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 5%;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
`

const Card = styled.div`
    width: 27%;
    display: flex;
    flex-direction: column;
`

const ImgSkills = styled.img`
    width: 100%;
    border-radius: 10px;
`

const ContactContainers = styled.div`
    color: #C5C6C7;
    margin-bottom: 1%;
    display: flex;
    align-items: center;
    margin-left: 10%;
    width: 80%;
`

const ContactImg = styled.img`
    width: 5%;
    border-radius: 50%;
    margin-right: 2%;
`

const JSReactRedux = styled.p`
    color: #C5C6C7;
`

const ContactLink = styled.a`
    color: #C5C6C7;
    text-decoration: none;
`

export default function About() {

    return (
        <Page>
            <Title>About</Title>

            <AboutContainer>
                <div>
                    <AboutPageImg src='./HomeScreenPicCopy.png' />
                </div>
                <AboutPageImgText>
                    Hey, I'm Danny. I am currently a first-year student at MIT. Before I started MIT in the fall of 2021, I was enrolled in Lambda School for a year (gap year). During my time at Lambda School, I learned the skills necessary to becoming a full stack web developer while working on many projects as well. Feel free to check out my <Link to='/projects' style={{ textDecoration: 'none', color: '#66FCF1' }}>projects!</Link>
                </AboutPageImgText>
            </AboutContainer>

            
            <SkillsTitle>Skills</SkillsTitle>
            <CardsContainer>
                <Card>
                    <ImgSkills src='https://cdn.mos.cms.futurecdn.net/hFm4iWXhbw4c4rdcMH8tUD.jpg' />
                </Card>
                <Card>
                    <ImgSkills src='https://a4jp.com/wp-content/uploads/2019/03/css.jpg' />
                </Card>
                <Card>
                    <ImgSkills src='https://miro.medium.com/max/800/1*bxEkHw1xewxOFjmGunb-Cw.png' />
                    <JSReactRedux>Includes React, Redux</JSReactRedux>
                </Card>
            </CardsContainer>
            <CardsContainer>
                <Card>
                    <ImgSkills src='https://www.itprotoday.com/sites/itprotoday.com/files/java-logo.png' />
                </Card>
                <Card>
                    <ImgSkills src='https://datawider.com/wp-content/uploads/2019/11/How-to-Learn-Python.jpg' />
                </Card>
            </CardsContainer>

            <ContactTitle>Contact</ContactTitle>
                <ContactContainers>
                    <ContactImg src="https://i.pinimg.com/originals/2b/b4/28/2bb428b7e4aa39078a119deccacae6f3.png" /> 
                    <div>davarg5@mit.edu</div>
                </ContactContainers>
                <ContactContainers>
                    <ContactImg src="https://thumbs.dreamstime.com/b/phone-icon-black-white-telephone-symbol-vector-illustration-handset-background-eps-format-132728273.jpg" /> 
                    <div>516-493-0591</div>
                </ContactContainers>
                <ContactContainers>
                    <ContactImg src="https://thumbs.dreamstime.com/b/linkedin-icon-logo-isolated-white-background-editable-eps-vector-illustration-152396326.jpg" /> 
                    <ContactLink href='https://linkedin.com/in/danielvargas916'>/danielvargas916</ContactLink>
                </ContactContainers>
                <ContactContainers>
                    <ContactImg src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png" /> 
                    <ContactLink href='https://github.com/davarg5'>/davarg5</ContactLink>
                </ContactContainers>
        </Page>
    )
}