import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Navigation = styled.nav`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    color: #66FCF1;
`


export default function Nav() {
     return (
         <Navigation>
             <Link to='/' style={{ textDecoration: 'none', color: '#66FCF1', fontWeight: 550 }}><h2>Daniel Vargas</h2></Link>
             {/* <Link to='/about' style={{ textDecoration: 'none', color: '#66FCF1', fontWeight: 550 }}>About</Link> */}
             <Link to='/education' style={{ textDecoration: 'none', color: '#66FCF1', fontWeight: 550 }}>Education</Link>
             <Link to='/projects' style={{ textDecoration: 'none', color: '#66FCF1', fontWeight: 550 }}>Projects</Link>
         </Navigation>
     )
}